@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Muli, Helvetica Neue, Helvetica, Arial, sans-serif;
  background: #fafafa;
}

.skeleton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.set-inner-html a {
  color: #049be5 !important;
  text-decoration: underline;
}
