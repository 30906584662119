.ck.ck-toolbar {
  --ck-color-toolbar-background: #014a62;

  --ck-color-button-default-background: transparent;
  --ck-color-button-default-hover-background: rgb(0, 51, 68);
  --ck-color-button-default-active-background: rgb(51, 110, 129);
  --ck-color-button-on-background: rgb(51, 110, 129);
  --ck-color-button-on-hover-background: rgb(51, 110, 129);
  --ck-color-split-button-hover-background: transparent;
  --ck-focus-outer-shadow: transparent;

  padding: 4px 8px;
  border: 0;
}

.ck.ck-toolbar__items > .ck.ck-button,
.ck.ck-toolbar__items .ck.ck-splitbutton > .ck.ck-button {
  color: #ffffff;
}

.ck.ck-toolbar.ck-rounded-corners {
  border-radius: 4px 4px 0 0;
}

.ck.ck-content {
  padding: 0 16px;
}
.ck.ck-content:not(.ck-preview) {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-top: 0;
}
.ck.ck-content:hover {
  border-color: rgba(0, 0, 0, 0.87);
}
.ck.ck-content.ck-preview {
  padding: 0;
  margin-top: -8px;
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable),
.ck.ck-editor__editable.ck-rounded-corners:not(.ck-editor__nested-editable) {
  border-radius: 0 0 4px 4px;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid #014a62;
  border-top: 0;
  box-shadow: none;
}

.ck.ck-content p {
  margin: 8px 0;
}

.ck.ck-content ol,
.ck.ck-content ul {
  margin: 8px 0 8px 16px;
}
